@import './_mixins.scss';
@import './_overrides.scss';
@import './_landing-table.scss';
@import './_spiral-circle.scss';
@import './_albersUSA.scss';
body {
  overflow: hidden auto;
}
.preloader {
  margin: 0 auto;
}

.helper-text p {
  @include carbon--type-style('body-short-01');
  padding-bottom: 10px;
}

.landing-page__banner {
  padding-top: $spacing-05;
  padding-bottom: $spacing-05;
  @include landing-page-background;
}

.section_block {
  padding-top: $spacing-05;
  padding-bottom: $spacing-05;
  @include landing-page-background;
}

#table-bottom {
  text-align: center;
  background-color: #ffffff;
  height: 70px;
}

#show-more-or-less {
  position: relative;
  top: 40%;
}

ul.horizontal-list li {
  display: inline;
  padding-right: 5px;
}

#main-content {
  padding-left: 50px;
}

#main-content a {
  text-decoration: none;
}

#main-content a:hover {
  text-decoration: underline;
}

#main-content a:visited {
  color: #0000ee;
}

.positioned-svg {
  margin-bottom: -2px;
}

@media print {
  @page {
    size: A4; /* DIN A4 standard, Europe */
    margin: 20mm 5mm 10mm 5mm;
  }
  html,
  body {
    width: 210mm;
    /* height: 297mm; */
    height: 282mm;
    font-size: 11px;
    background: #fff;
    overflow: visible;
  }
  body {
    margin: 0;
    padding: 0;
  }
}
