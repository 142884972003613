#two-columns-in-bubble-map {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  padding-right: 0;
  padding-left: 0;
  width: 91vw;
  height: auto;
}
#bubble-map-left-text {
  flex: 20%;
  max-width: 25vw;
}
