.login-form {
  width: 250px;
  height: 200px;
  position: relative;
  margin: 0 auto;
}

// .login-row:before {
//     content: "";
//     position: absolute;
//     background: inherit;
//     z-index: -1;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     box-shadow: inset 0 0 2000px rgba(255, 255, 255, .5);
//     filter: blur(5px);
//     margin: -20px;
// }

.bx--form-item {
  padding-top: 10px;
  padding-bottom: 10px;
}

#login-button {
  position: absolute;
  left: 30%;
  bottom: 50px;
}

// #login-button.bx--btn--sm {
//   padding-right: 10px;
// }

.login-row {
  margin-top: 400px;
  margin-right: 5%;
  margin-left: auto;
  // box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .6);
  border-radius: 0px;
  position: relative;
  z-index: 1;
  // background: white;
  background-color: rgba(243, 243, 243, 0.8);
  overflow: hidden;
  // opacity: 0.5;
}

.login-footer {
  position: fixed;
  bottom: 10px;
  width: 100%;
  // color: $text-01;

  text-align: center;
}

#login-panel-title {
  // text-align: left;
  padding-top: 40px;
  margin-bottom: 20px;
  color: $text-04;
  font-weight: 550;
  text-shadow: 1px 1px 10px $text-03;
}

// #login-panel-footer {
//     text-align: center;
//     margin: 10;
//     position: absolute;
//     bottom: 0;
//     font-size: 1rem // color: $text-04;
//         // @include carbon--type-style('caption-01');
// }

#full-page {
  display: block;
  position: absolute;
  height: auto;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-image: url(static/Fairmont_Kea_Lani_Maui_Original_4637.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.login-img {
  width: 150px;
  margin-top: 3rem;
  margin-bottom: 2rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90px;
}
