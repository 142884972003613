@import './_dropdown.scss';
@import './accrdion.scss';
@import './_revpar-chart.scss';
@import './_map.scss';

.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

#market-details.wrapper div {
  border-left: 1px solid #ccc;
  padding: 1rem;
}

#market-details.wrapper {
  padding-left: 0;
}

#test {
  color: $interactive-01;
}

.recharts-legend-wrapper {
  bottom: 15px;
}

.publication-date {
  @include carbon--type-style('code-02');
}
