@import './mixins.scss';

a.bx--header__name {
    font-size: 1rem
}

.bx--side-nav__link {
    width: 52px;
    height: 52px;
}

.bx--side-nav__link-text {
    height: 20px;
    width: 20px;
}

.bx--side-nav__items {
    //     width: 52px;
    padding-top: 0px;
}

// .bx--side-nav__navigation {
//     width: 52px;
// }

.bx--side-nav {
    max-width: 52px;
    background-color: $ui-01;
}

.bx--header {
    border-bottom: 0
}