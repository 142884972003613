.recharts-responsive-container {
    margin-bottom: 20px
}

#revpar-chart-title {
    margin-top: 20px;
    padding: 20px;
    background-color: white
}

.recharts-wrapper {
    background-color: white
}