.bx--data-table-container {
  position: relative;
}

.filter {
  position: absolute;
  top: 15px;
  right: 10px;
  // font-family: sans-serif;
  width: 450px;
  float: left;
}
#print {
  float: left;
  margin-right: 5px;
}

.Select-control {
  table-layout: fixed;
}

.Select-input {
  overflow: hidden;
  max-width: calc(50% - 20px);
}
td {
  text-align: center;
}
th.datatable-header {
  padding-left: 0;
  padding-right: 0;
}

span.bx--table-header-label {
  margin-left: 5%;
}
.bx--data-table td {
  padding-left: 18px;
}

div .react-select__control {
  border-radius: 0;
}
