@import 'carbon-components/scss/globals/scss/vendor/@carbon/type/scss/font-family.scss';
@import 'carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/breakpoint.scss';
@import 'carbon-components/scss/globals/scss/typography.scss';
@import 'carbon-components/scss/globals/scss/vars.scss';
@import './components/HorizonHeader/_horizon-header.scss';
@import './contents/LandingPage/_landing-page.scss';
@import './contents/MarketPage/_market-page.scss';
@import './contents/VariablePage/variable-page.scss';
@import './components/auth/_login.scss';

h1 {
  @include carbon--type-style('productive-heading-05');
}

h2 {
  font-family: carbon--font-family('sans');
  font-size: carbon--rem(24px);
  font-weight: carbon--font-weight('regular');
  line-height: carbon--rem(36px);
  letter-spacing: 0;
}

h3 {
  @include carbon--type-style('productive-heading-03');
}

h4 {
  @include carbon--type-style('productive-heading-02');
}

p {
  @include carbon--type-style('body-short-02');
}

.recharts-wrapper,
.recharts-surface {
  @media print {
    width: calc(100%) !important;
  }
}
