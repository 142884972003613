// /**
// * ----------------------------------------------
// * Demo styles
// * ----------------------------------------------
// **/
// .accordion {
//     background-color: white;

// }

// .accordion__item+.accordion__item {
//     border-top: 1px solid $ui-background;
// }

// .accordion__button {
//     // background-color: #f4f4f4;
//     color: #444;
//     cursor: pointer;
//     padding: 18px;
//     width: 100%;
//     text-align: left;
//     border: none;
// }

// .accordion__button:hover {
//     background-color: #ddd;
// }

// .accordion__button:before {
//     display: inline-block;
//     content: '';
//     height: 10px;
//     width: 10px;
//     margin-right: 12px;
//     border-bottom: 2px solid currentColor;
//     border-right: 2px solid currentColor;
//     transform: rotate(-45deg);
// }

// .accordion__button[aria-expanded='true']::before,
// .accordion__button[aria-selected='true']::before {
//     transform: rotate(45deg);
// }

// .accordion__panel {
//     padding: 20px;
//     animation: fadein 0.35s ease-in;
// }

// /* -------------------------------------------------- */
// /* ---------------- Animation part ------------------ */
// /* -------------------------------------------------- */

// @keyframes fadein {
//     0% {
//         opacity: 0;
//     }

//     100% {
//         opacity: 1;
//     }
// }


.accordion {
    transition: 260ms cubic-bezier(0.4, 0, 0.2, 1);
}

.accordion__toggle {
    transition: min-height 260ms cubic-bezier(0.4, 0, 0.2, 1);
}

.accordion--is-closed+.accordion--is-open {
    margin-top: 10px;
}

.accordion+.accordion {
    .accordion__divider {
        display: block;
    }
}

.accordion--is-open+.accordion--is-closed {
    .accordion__divider {
        display: none;
    }
}

.collapse-css-transition {
    /* Accordion library */
    transition: height 260ms cubic-bezier(0.4, 0, 0.2, 1);
}

.accordion__toggle svg path {
    fill: $ui-background;
}