#two-columns {
  display: flex;
  justify-content: space-between;
  padding: 25px;
  flex-wrap: wrap;
}

.helper-text {
  flex: 10%;
}

#spiral-chart {
  flex: 40%;
}
