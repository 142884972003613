@import './_dropdown.scss';
@import './variable-chart.scss';

#variable-details.wrapper div {
  border-left: 1px solid #ccc;
  padding: 1rem;
}

#variable-details.wrapper {
  padding-left: 0;
}
